import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfBrand from "@/vue/domain/brand/df-brand";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import DfContent from "@/vue/domain/content/df-content";

@Component
export default class DfFooterComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get brandLogo(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO, `${Utils.getPublicPath()}/assets/logo.png`);
  }

  get links(): Array<any> {
    const brandPages: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "PAG");
    return brandPages
      .filter((page: DfContent) => {
        return Utils.getPropertyValues(page, Utils.PROPERTY_POSITION, "LIST").findIndex((value: any) => value.key === Utils.PROPERTY_FOOTER) > -1;
      })
      .map((page: DfContent) => {
        return {
          label: page.name,
          path: `/contenuti/${page.alias}`,
          url: Utils.getPropertyValue(page, Utils.PROPERTY_URL, "STRING"),
        };
      });
  }

  get brandLogoFooter(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO_FOOTER, null);
  }

  get brandLogoImageUrl(): string {
    return this.brandLogoFooter ? this.brandLogoFooter : this.brandLogo;
  }

  get brandFooter(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_FOOTER, "TEXT");
  }
}
